import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  MdxWrapper,
  PinBlockGroup,
  AggregatorPin4,
  CategoryTemplate,
  Typography,
} from '../../components'
import { path, pathOr } from 'ramda'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const propTypes = {
  data: PropTypes.shape({
    category: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        title: PropTypes.string,
        summary: PropTypes.string,
        content: PropTypes.string,
      }),
    }),
    projects: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }),
}

export const ProjectCategoryTemplate = ({ data }) => {
  const {
    category: {
      frontmatter: { title, summary, content },
    },
    projects: { edges: projects },
  } = data

  const breadcrumbs = [
    {
      label: 'Catalysing',
      url: '/catalysing',
    },
    {
      label: title,
    },
  ]

  const categoryProjects = projects.filter(({ node }) =>
    pathOr([], ['frontmatter', 'categories'], node).includes(title),
  )
  return (
    <MDXProvider>
      <CategoryTemplate breadcrumbs={breadcrumbs}>
        <Divider thickness={4} />
        <Typography tag="h1" type="boxHeading" className="mb-8">
          {title}
        </Typography>
        <MdxWrapper>
          {summary && <div>{summary}</div>}
          {content && <MDXRenderer>{content}</MDXRenderer>}
        </MdxWrapper>
        {categoryProjects.length > 0 && (
          <>
            <Divider thickness={4} />
            {title !== 'Opportunities' && (
              <Typography tag="h2" className="mb-8">
                Projects
              </Typography>
            )}
            <PinBlockGroup columns={2}>
              {categoryProjects.map(({ node }, i) => {
                return (
                  <AggregatorPin4
                    key={i}
                    title={path(['frontmatter', 'title'], node)}
                    thumbnailSrc={path(['frontmatter', 'featuredImage'], node)}
                    href={path(['fields', 'slug'], node)}
                  >
                    {path(['frontmatter', 'excerpt'], node)}...
                  </AggregatorPin4>
                )
              })}
            </PinBlockGroup>
          </>
        )}
      </CategoryTemplate>
    </MDXProvider>
  )
}

ProjectCategoryTemplate.propTypes = propTypes

const ProjectCategory = ({ data }) => {
  const {
    category: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <ProjectCategoryTemplate data={data} />
    </LayoutFullWidth>
  )
}

ProjectCategory.propTypes = propTypes

export default ProjectCategory

/** @todo only fetch projects by category */
export const pageQuery = graphql`
  query ProjectCategoryTemplate($slug: String!) {
    category: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
        title
        summary
        content
      }
    }
    projects: allMdx(
      filter: { fields: { contentType: { eq: "projects" } } }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  height
                  src
                  srcSet
                  width
                }
              }
            }
            categories
          }
        }
      }
    }
  }
`
